import React, { Dispatch, SetStateAction } from 'react';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { getDocumentInitiationMessage } from './BatchStudentsHelpers';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
interface Props {
  documentsData: {
    createdAt: Date;
    status: string;
    total: number;
    pending: number;
    completed: number;
    failed: number;
    failedRequests: {
      errMessage?: string;
      moduleId?: string;
    }[];
  } | null;
  documentType: string;
  setExportFailureModal: Dispatch<SetStateAction<boolean>>;
  exportFailureModal: boolean;
}

export default function DownloadDocumentsModal(props: Props) {
  const { documentsData, documentType, setExportFailureModal, exportFailureModal } = props;
  const { t } = useI18n();

  const ExportFailure = () => {
    return documentsData?.failedRequests?.map((item: any) => (
      <FailureRecordWrapper>
        <MediumTextSelect
          value={item?.moduleId || 'N/A'}
          fontWeight={'normal'}
          fontFamily={fonts.semibold}
          color={colors.primaryText}
        />
        <MediumTextSelect value={`: ${item?.errMessage}`} fontWeight={'normal'} />
      </FailureRecordWrapper>
    ));
  };

  return (
    <>
      {documentsData ? (
        <ContainerWrapper>
          <MediumTextSelect
            fontWeight={'normal'}
            value={
              documentsData?.pending
                ? t('generationInProcess.message.text',
                    {
                      values: {
                        documentName: getDocumentInitiationMessage(documentType),
                      },
                    },
                  )
                : t('generationComplete.message.text', {
                    values: {
                      documentName: getDocumentInitiationMessage(documentType, 'completion'),
                    },
                  })
            }
          />
          <ContentWrapper>
            {documentType === 'PRINTABLE_ID_CARDS_GENERATION' ? (
              <CreateInfoItem
                label={t('lastGeneratedDate.label')}
                value={
                  documentsData?.createdAt
                    ? format(documentsData?.createdAt, DT.DATE_TIME_SLASH_12_HOUR)
                    : '-'
                }
              />
            ) : (
              <>
                <CreateInfoItem
                  label={t('pending.label')}
                  value={documentsData?.pending}
                  width={100}
                />
                <CreateInfoItem
                  label={t('failed.label')}
                  value={documentsData?.failed}
                  width={100}
                  showDescriptionIcon={Boolean(
                    documentsData?.failed && documentsData?.failedRequests?.length > 0,
                  )}
                  handleInfoIcon={() => setExportFailureModal(true)}
                />
                <CreateInfoItem
                  label={t('completed.label')}
                  value={documentsData?.completed}
                  width={100}
                />
                <CreateInfoItem label={t('total.label')} value={documentsData?.total} width={100} />
              </>
            )}
          </ContentWrapper>
        </ContainerWrapper>
      ) : (
        <ContainerWrapper>
          <MediumTextSelect
            fontWeight={'normal'}
            value={t('generationInitiation.message.text', {
              values: { documentName: getDocumentInitiationMessage(documentType) },
            })}
          />
        </ContainerWrapper>
      )}

      <NormalModal
        setModalVisible={() => setExportFailureModal(false)}
        modalVisible={exportFailureModal}
        Headerpopup={t('exportFailure.label')}
        handleSave={() => {}}
        showFooter={false}
        width={544}
        height={176}
        contentStyles={{ paddingBottom: 24 }}>
        <ExportFailure />
      </NormalModal>
    </>
  );
}

const ContainerWrapper = styled.View`
  min-height: 44px;
`;

const ContentWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 24px;
`;

const FailureRecordWrapper = styled.View`
  display: block;
  margin-bottom: 4px;
`;
